<template>
  <div>
    <el-dialog
      append-to-body
      :title="type === 'batch' ? '批量修改等级' : '修改等级'"
      :visible.sync="visible"
      width="631px"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <el-form v-loading="dialogLoading" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="等级:" prop="levelID">
          <el-select style="width: 180px" v-model="ruleForm.levelID" clearable placeholder="请选择会员等级">
            <el-option v-for="item in vipList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCanceled">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="handleConfirmed">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { changeVipLevel, getVipList } from '@/api/vipLevel'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selectIdList: {
      type: Array,
      default: []
    },
    type: {
      type: String,
      default: 'single'
    },
    sup_this: {
      type: Object,
      default: () => ({})
    },
    userLevel: {
      type: Number,
      default: 0
    }
  },
  watch: {
    levelIDList: {
      handler(n) {
        this.ruleForm.levelID = this.userLevel
      },
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      ruleForm: {
        levelID: ''
      },
      rules: {
        levelID: [{ required: true, message: '请选择等级', trigger: 'change' }]
      },
      vipList: [],
      dialogLoading: false
    }
  },
  async created() {
    this.dialogLoading = true
    const page = { pageIndex: 1, pageSize: 0 }
    try {
      const { code, detail } = await getVipList({ page })
      if (!$SUC(code)) {
        this.vipList = detail.map(({ id, level }) => ({
          value: id,
          label: level
        }))
      }
    } finally {
      this.dialogLoading = false
    }

    this.ruleForm.levelID = this.$attrs.curLevel
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
      this.$refs.ruleForm.resetFields()
    },
    handleCanceled() {
      this.$emit('update:visible', false)
      this.$refs.ruleForm.resetFields()
    },
    async handleConfirmed() {
      this.loading = true
      const userLevel = this.ruleForm.levelID
      const data = {
        userLevel,
        idList: this.selectIdList
      }
      //使用selectIdList调用保存接口,成功回调提示保存成功
      try {
        const { code } = await changeVipLevel(data)
        if (code == 0) {
          this.loading = false
          this.$emit('update:visible', false)
          this.$refs.ruleForm.resetFields()
          this.$emit('refreshPage')
          this.sup_this.init()
          this.$message.success('修改成功')
        }
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>
