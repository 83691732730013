import { render, staticRenderFns } from "./modifyLevel.vue?vue&type=template&id=677987df&scoped=true&"
import script from "./modifyLevel.vue?vue&type=script&lang=js&"
export * from "./modifyLevel.vue?vue&type=script&lang=js&"
import style0 from "./modifyLevel.vue?vue&type=style&index=0&id=677987df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677987df",
  null
  
)

export default component.exports